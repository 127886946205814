var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"scroll m-5",attrs:{"id":"RSession"}},[_c('table',{staticClass:"text-center shadow-md bg-white",staticStyle:{"height":"70%","width":"99%"}},[_c('tr',{staticClass:"HeaderTableColor"},[_c('th',{staticClass:"text-center w-48"},[_vm._v(_vm._s(_vm.$t("Doctors")))]),_c('th',{staticClass:"text-center w-64"},[_vm._v(" "+_vm._s(_vm.$t("AppointmentDate"))+" ")]),_c('th',{staticClass:"text-center"},[_vm._v(" "+_vm._s(_vm.$t("Status"))+" ")]),_c('th',{staticClass:"text-center"},[_vm._v(" "+_vm._s(_vm.$t("PaymentStatus"))+" ")]),_c('th',{staticClass:"text-center"},[_vm._v(" "+_vm._s(_vm.$t("Time"))+" ")]),_vm._m(0)]),_vm._l((_vm.Model),function(item,index){return _c('tr',{key:index,staticClass:"text-xl"},[_c('td',[_c('div',[_c('imageLazy',{attrs:{"imageStyle":{ 'border-radius': '20px' ,'width':'40px','height':'40px'},"src":_vm.baseURL + item.Doctor.ProfileImagePath,"placeHolderType":"doctor"}})],1),(item.Doctor.Name)?_c('p',[_vm._v(_vm._s(_vm.$t("Dr"))+"."+_vm._s(item.Doctor.Name))]):_vm._e()]),_c('td',[_vm._v(_vm._s(item.DoctorSession.DateFormatted))]),(item.Status)?_c('td',[_vm._v(_vm._s(item.Status.Name))]):_c('td'),(item.PaymentStatus)?_c('td',[_vm._v(_vm._s(item.PaymentStatus.Name))]):_c('td'),_c('td',[_vm._v(" "+_vm._s(_vm.$t("From"))+" : "+_vm._s(item.DoctorSession.DateFrom.slice(11, 16))+" "),_c('br'),_vm._v(" "+_vm._s(_vm.$t("To"))+": "+_vm._s(item.DoctorSession.DateTo.slice(11, 16))+" ")]),_c('td',{class:[
          (item.PaymentStatusID != 5 && item.StatusID != 5) ||
          item.StatusID == 3
            ? ' bg-danger text-white '
            : 'bg-primary text-white ',
        ],staticStyle:{"max-width":"60px"}},[(
            item.PaymentStatusID == 1 &&
            item.StatusID != 5 &&
            item.StatusID != 3
          )?_c('div',{staticClass:"handclick p-2",on:{"click":function($event){return _vm.PayNow(item)}}},[_vm._v(" "+_vm._s(_vm.$t("PayNow"))+" "),_c('feather-icon',{staticClass:"ml-1",attrs:{"icon":"DollarSignIcon","svgClasses":"h-4 w-4"}})],1):_vm._e(),(item.StatusID == 5|| item.PatientJoined)?_c('div',{staticClass:"handclick"},[_vm._v(" "+_vm._s(_vm.$t("Done"))+" ")]):_vm._e(),(item.StatusID == 3)?_c('div',{staticClass:"handclick w-full pr-2"},[_vm._v(" "+_vm._s(_vm.$t("Canceled"))+" ")]):_vm._e(),(
            _vm.checkDate(
              item.DoctorSession.DateFrom,
            ) &&
           
            item.PaymentStatusID == 5 && item.StatusID != 5 && item.StatusID != 3 && !item.PatientJoined)?_c('div',[_c('div',[_c('feather-icon',{staticClass:"ml-1",attrs:{"icon":"LinkIcon","svgClasses":"h-4 w-4"}})],1),_c('p',{staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.joinMeeting(item)}}},[_vm._v(" "+_vm._s(_vm.$t("Join"))+" ")])]):_c('div',[(item.StatusID != 5 && item.PaymentStatusID == 5 && !item.PatientJoined)?_c('p',[_vm._v(" "+_vm._s(_vm.$t("CommingSoon"))+" ")]):_vm._e()]),(
            item.StatusID != 5 && item.StatusID != 3&& !item.PatientJoined
          )?_c('div',{staticClass:"vx-row w-full mb-4"},[_c('div',{staticClass:"lg:w-1/6 w-full"}),_c('div',{staticClass:"lg:w-1/6 w-full"},[_c('u',{staticClass:"w-full",staticStyle:{"font-size":"10px","font-color":"#ffffff","cursor":"pointer"},on:{"click":function($event){return _vm.CancelSession(item)}}},[_vm._v(_vm._s(_vm.$t("Cancel")))])]),_c('div',{staticClass:"lg:w-1/6 w-full"}),_c('div',{staticClass:"lg:w-1/6 w-full pl-8"},[_c('u',{staticClass:"w-full",staticStyle:{"font-size":"10px","font-color":"#ffffff","cursor":"pointer"},on:{"click":function($event){return _vm.EditSession(item)}}},[_vm._v(_vm._s(_vm.$t("Edit")))])])]):_vm._e()])])}),(!_vm.Model || _vm.Model.length == 0)?_c('tr',[_c('td'),_c('td',[_c('h3',{staticClass:"w-full sm:w-auto text-center text-primary mt-5"},[_vm._v(" "+_vm._s(_vm.$t("NoDataToshow"))+" ")])]),_c('td')]):_vm._e()],2),_c('vs-popup',{attrs:{"fullscreen":"","title":"","active":_vm.showEditSessionDialog},on:{"update:active":function($event){_vm.showEditSessionDialog=$event}}},[(_vm.showEditSessionDialog)?_c('EditSessionAppointmet',{attrs:{"doctor":_vm.doctorModel,"editSessionModel":_vm.EditSessionModel},on:{"closePop":function($event){_vm.showEditSessionDialog = false},"SetNewSession":_vm.SetNewSession,"successEdit":function($event){return _vm.successEdit()}}}):_vm._e()],1),_c('vs-popup',{attrs:{"fullscreen":"","title":"","active":_vm.showCancelDialog},on:{"update:active":function($event){_vm.showCancelDialog=$event}}},[(_vm.showCancelDialog)?_c('CancelSessionAlert',{attrs:{"data":_vm.CancelSessionModel},on:{"closePop":function($event){_vm.showCancelDialog = false;
        _vm.showConfirmMsg = false;},"CancelSessionDone":function($event){_vm.CloseSeccussCancelPopup();
        _vm.showCancelDialog = false;}}}):_vm._e()],1),_c('vs-popup',{attrs:{"fullscreen":"","title":"","active":_vm.showConfirmMsg},on:{"update:active":function($event){_vm.showConfirmMsg=$event}}},[(_vm.showConfirmMsg)?_c('SuccessPopup',{attrs:{"Message":_vm.$t('CancelledConfirm'),"data":_vm.CancelSessionModel},on:{"CloseSeccussPopup":function($event){return _vm.CloseSeccussCancelPopup()},"closePop":function($event){_vm.showConfirmMsg = false}}}):_vm._e()],1),_c('vs-popup',{attrs:{"title":"","active":_vm.ShowAlertPopup},on:{"update:active":function($event){_vm.ShowAlertPopup=$event}}},[(_vm.ShowAlertPopup)?_c('EditSessionAlert',{attrs:{"Message":_vm.$t('CantEditSession')},on:{"CloseSeccussPopup":function($event){_vm.ShowAlertPopup = false;
        _vm.sear;}}}):_vm._e()],1),_c('vs-popup',{attrs:{"title":"","active":_vm.ShowJoinPopup},on:{"update:active":function($event){_vm.ShowJoinPopup=$event}}},[(_vm.ShowJoinPopup)?_c('JoinSessionAlertPopup',{attrs:{"sessionModel":_vm.sessionModel},on:{"closePop":function($event){_vm.ShowJoinPopup = false},"joinSession":_vm.ChangeReservationJoin}}):_vm._e()],1)],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('th',{staticClass:"text-center"},[_c('div',{staticClass:"MyPopClose"})])
}]

export { render, staticRenderFns }