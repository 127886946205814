<template>
  <div id="RSession" class="scroll m-5">
    <table
      style="height: 70%; width: 99%"
      class="text-center shadow-md bg-white"
    >
      <tr class="HeaderTableColor">
        <th class="text-center w-48">{{ $t("Doctors") }}</th>
        <th class="text-center w-64">
          {{ $t("AppointmentDate") }}
        </th>

        <th class="text-center">
          {{ $t("Status") }}
        </th>
        <th class="text-center">
          {{ $t("PaymentStatus") }}
        </th>
        <th class="text-center">
          {{ $t("Time") }}
        </th>
        <th class="text-center">
          <div class="MyPopClose"></div>
        </th>
      </tr>

      <tr class="text-xl" v-for="(item, index) in Model" :key="index">
        <td>
          <div>
             <imageLazy
                      :imageStyle="{ 'border-radius': '20px' ,'width':'40px','height':'40px'}"
                      
                      :src="baseURL + item.Doctor.ProfileImagePath"
                      placeHolderType="doctor"
                    />
           
          </div>
          <p v-if="item.Doctor.Name">{{ $t("Dr") }}.{{ item.Doctor.Name }}</p>
        </td>

        <td>{{ item.DoctorSession.DateFormatted }}</td>

        <td v-if="item.Status">{{ item.Status.Name }}</td>
        <td v-else></td>

        <td v-if="item.PaymentStatus">{{ item.PaymentStatus.Name }}</td>
        <td v-else></td>

        <td>
          {{ $t("From") }} : {{ item.DoctorSession.DateFrom.slice(11, 16) }}
          <br />
          {{ $t("To") }}: {{ item.DoctorSession.DateTo.slice(11, 16) }}
        </td>

        <td
          :class="[
            (item.PaymentStatusID != 5 && item.StatusID != 5) ||
            item.StatusID == 3
              ? ' bg-danger text-white '
              : 'bg-primary text-white ',
          ]"
          style="max-width: 60px"
        >
          <div
            class="handclick p-2"
            @click="PayNow(item)"
            v-if="
              item.PaymentStatusID == 1 &&
              item.StatusID != 5 &&
              item.StatusID != 3
            "
          >
            {{ $t("PayNow") }}
            <feather-icon
              icon="DollarSignIcon"
              svgClasses="h-4 w-4"
              class="ml-1"
            />
          </div>
         

          <div class="handclick" v-if="item.StatusID == 5|| item.PatientJoined">
            {{ $t("Done") }}
          </div>

          <div class="handclick w-full pr-2" v-if="item.StatusID == 3">
            <!-- <p
              style="font-size: 12px;"

            > -->
            {{ $t("Canceled") }}
            <!-- </p> -->
          </div>
          <!-- -->
          <div
            v-if="
              checkDate(
                item.DoctorSession.DateFrom,
              ) &&
             
              item.PaymentStatusID == 5 && item.StatusID != 5 && item.StatusID != 3 && !item.PatientJoined"
          >
            <div>
              <feather-icon icon="LinkIcon" svgClasses="h-4 w-4" class="ml-1" />
            </div>
            <!-- font-size: 15px;  -->
            <p style="cursor: pointer" @click="joinMeeting(item)">
              {{ $t("Join") }}
            </p>
          </div>
          <div v-else>
            <p v-if="item.StatusID != 5 && item.PaymentStatusID == 5 && !item.PatientJoined">
              {{ $t("CommingSoon") }}
            </p>
          </div>

          <div
            class="vx-row w-full mb-4"
            v-if="
              item.StatusID != 5 && item.StatusID != 3&& !item.PatientJoined
            "
          >
            <div class="lg:w-1/6 w-full"></div>
            <div class="lg:w-1/6 w-full">
              <u
                class="w-full"
                @click="CancelSession(item)"
                style="font-size: 10px;  font-color: #ffffff;cursor: pointer;"
                >{{ $t("Cancel") }}</u>
            </div>
            <div class="lg:w-1/6 w-full"></div>
            

            <div class="lg:w-1/6 w-full pl-8">
              <u
                class="w-full"
                @click="EditSession(item)"
                style="font-size: 10px; font-color: #ffffff; cursor: pointer"
                >{{ $t("Edit") }}</u
              >
            </div>
          </div>
        </td>
      </tr>
      <tr v-if="!Model || Model.length == 0">
        <td></td>
        <td>
          <h3 class="w-full sm:w-auto text-center text-primary mt-5">
            {{ $t("NoDataToshow") }}
          </h3>
        </td>
        <td></td>
      </tr>
    </table>

    <vs-popup fullscreen title="" :active.sync="showEditSessionDialog">
      <EditSessionAppointmet
        v-if="showEditSessionDialog"
        @closePop="showEditSessionDialog = false"
        @SetNewSession="SetNewSession"
        :doctor="doctorModel"
        :editSessionModel="EditSessionModel"
        @successEdit="successEdit()"
      ></EditSessionAppointmet>
    </vs-popup>

    <vs-popup fullscreen title="" :active.sync="showCancelDialog">
      <CancelSessionAlert
        v-if="showCancelDialog"
        @closePop="
          showCancelDialog = false;
          showConfirmMsg = false;
        "
        @CancelSessionDone="
          CloseSeccussCancelPopup();
          showCancelDialog = false;
        "
        :data="CancelSessionModel"
      ></CancelSessionAlert>
    </vs-popup>

    <!-- CancelledConfirm -->
    <vs-popup fullscreen title="" :active.sync="showConfirmMsg">
      <SuccessPopup
        :Message="$t('CancelledConfirm')"
        v-if="showConfirmMsg"
        @CloseSeccussPopup="CloseSeccussCancelPopup()"
        @closePop="showConfirmMsg = false"
        :data="CancelSessionModel"
      ></SuccessPopup>
    </vs-popup>

    <vs-popup title="" :active.sync="ShowAlertPopup">
      <EditSessionAlert
        :Message="$t('CantEditSession')"
        v-if="ShowAlertPopup"
        @CloseSeccussPopup="
          ShowAlertPopup = false;
          sear;
        "
      ></EditSessionAlert>
    </vs-popup>

    <vs-popup title="" :active.sync="ShowJoinPopup">
      <JoinSessionAlertPopup
        :sessionModel="sessionModel"
        v-if="ShowJoinPopup"
        @closePop="ShowJoinPopup = false"
        @joinSession="ChangeReservationJoin"
      ></JoinSessionAlertPopup>
    </vs-popup>
  </div>
</template>
<script>
import { domain } from "@/gloabelConstant.js";
import EditSessionAppointmet from "@/views/Patient/Reservation/EditSessionAppointmet.vue";
import CancelSessionAlert from "@/views/Patient/Reservation/CancelSessionAlert.vue";
import EditSessionAlert from "@/views/pages/register/SuccessPopup.vue";
import modulePatient from "@/store/Patient/modulePatient.js";
import SuccessPopup from "@/views/pages/register/SuccessPopup.vue";
import JoinSessionAlertPopup from "@/views/Patient/Reservation/JoinSessionAlertPopup.vue";
import imageLazy from "@/components/image_lazy.vue";
export default {
  data() {
    return {
      baseURL: domain,
      EditSessionModel: {},
      showEditSessionDialog: false,
      showCancelDialog: false,
      showConfirmMsg: false,
      doctorModel: {},
      ShowAlertPopup: false,
      ShowJoinPopup: false,
      CancelSessionModel: {},
      sessionModel: {},
    };
  },
  components: {
    EditSessionAppointmet,
    CancelSessionAlert,
    EditSessionAlert,
    SuccessPopup,
    JoinSessionAlertPopup,
    imageLazy
  },
  props: {
    Model: {
      type: Array,
      required: true,
    },
  },
  methods: {
    ChangeReservationJoin(reservationId)
    {
        this.$store.dispatch("patientList/ChangeReservationJoin", reservationId).then(res=>{
              this.$emit("refreshDataAfterCanceletion");
        })
    },

    CloseSeccussCancelPopup() {
      debugger;
      this.showConfirmMsg = false;
      // this.SearchAppointment();
      this.$emit("refreshDataAfterCanceletion");
    },
    SetNewSession(sessionModel) {
      this.showSetSession = true;
      this.showEditSessionDialog = false;
      this.sessionModel = sessionModel;
    },
    successEdit() {
      this.SearchAppointment();
    },
    PayNow(data) {
          data.FrontUrl = window.location.origin
      this.$store
        .dispatch("patientList/AppointmentReservationPaymentDatail", data)
        .then((res) => {
          debugger;
            window.location.href  = res.data.Data.transaction.url;
          // document.getElementById("amount").value = res.data.Data.amount;
          // document.getElementById("customer_email").value =
          //   res.data.Data.customer_email;
          // document.getElementById("signature").value = res.data.Data.signature;
          // document.getElementById("merchant_reference").value =
          //   res.data.Data.merchant_reference;
          // document.getElementById("paymentForm").submit();
        });
    },
    SearchAppointment() {
      debugger;
      var search = {
        patientID: this.$store.state.AppActiveUser.Patient.ID,
        IsNext: true,
        DateFrom: new Date(),
      };

      this.$store
        .dispatch("patientList/SearchPatientReservationSessions", search)
        .then((res) => {
          if (res.status == 200) {
            this.Model = res.data.Data;
            if (this.Model == null || this.Model.length == 0) {
              this.$vs.notify({
                title: this.$t("NoData"),
                text: this.$t("NoDataToshow"),
                iconPack: "feather",
                icon: "icon-alert-circle",
                color: "warning",
              });
            }
          }
        })
        .catch(() => {
          window.showError();
        });
    },
    createSeeionMeeting(sesstionId)
    {
          this.$store
        .dispatch("auth/CreateMeetingSession", sesstionId)
        .then((res) => {
          if (res.status == 200) {
                this.sessionModel.JoinUrl =   res.data.Data.AttendeeUrl
               this.ShowJoinPopup = true;
          }
        })
    },
    joinMeeting(item) { 
      debugger;
      this.sessionModel.ID=item.ID;
      this.sessionModel.JoinUrl = item.AttendeeUrl;

 
      var DateNow = new Date();
      debugger;

      var DateStart = new Date(item.DoctorSession.DateFrom);
      debugger;
      const milliseconds = DateStart - DateNow;

      var Minutes = Math.floor(milliseconds / 60000);

      if (Minutes <= 5) {
          this.createSeeionMeeting(item.ID);
      } else {
        this.$vs.notify({
          color: "danger",
          position: "right-bottom",
          text: "this meeting can't start now",
        });
      }
    },
    CancelSession(CancelSessionModel) {
      debugger;
    
      this.showEditSessionDialog = false;
      this.showCancelDialog = true;
      this.CancelSessionModel = CancelSessionModel;
    },

    checkDate(sesstionDate) {
      debugger;
      const current = new Date();
      sesstionDate = new Date(sesstionDate);
      var diffMs=sesstionDate-current;
      var diffMins = Math.round(diffMs / 60000);

      if (
        diffMins <= 5
      )
        return true;
      else return false;
    },
    EditSession(model) {
      debugger;
      var dayNow = new Date();
      var myDate = new Date(model.DoctorSession.DateFrom);
      dayNow = new Date(dayNow.getTime() + 3 * 24 * 60 * 60 * 1000);
      var date1 = dayNow.getTime();
      var date2 = myDate.getTime();
      if (date1 > date2) {
        this.ShowAlertPopup = true;
      } else {
        if (model.DoctorID > 0) {
          //doctorID
          this.EditSessionModel = model;
          this.EditSessionModel.viewType = 1;
          this.ViewEditSessionDialog(model.DoctorID);
        }
      }
    },

    ViewEditSessionDialog(doctorID) {
      debugger;

      this.$vs.loading();
      this.$store.dispatch("DoctorList/GetDoctor", doctorID).then((res) => {
        debugger;
        this.doctorModel = res.data.Data;
        this.showCancelDialog = false;
        this.showEditSessionDialog = true;
        debugger;
        this.$vs.loading.close();
      });
      debugger;

      this.$vs.loading.close();
      debugger;
    },
    RefershGrid() {
      this.$emit("SearchAppointment", true);
    },
  },

  created() {
    if (!modulePatient.isRegistered) {
      this.$store.registerModule("patientList", modulePatient);
      modulePatient.isRegistered = true;
    }
    const StatusID = this.$route.params.StatusID;
    const ReservationID = this.$route.params.ReservationID;
    if (StatusID && ReservationID) {
      if (StatusID == 14) {
        this.$router.push({
          name: "BookingSummary",
          params: { Flag: 1, ID: ReservationID },
        });
      } else if (StatusID != 14 && ReservationID) {
        window.showError($t("Paymentfail"));
      }
    }
  },
};
</script>
<style>
.downloadCell {
  min-width: 50px;
  max-width: 60px;
  padding: 0px;
}
#RSession table {
  border-collapse: collapse;
  border-radius: 36px !important;
  -moz-border-radius: 36px;
  margin-left: 0.3rem;
  margin-bottom: 0.2rem;
}

#RSession td,
#RSession th {
  border-left: solid #00000029 1px;
  border-top: solid hsla(0, 0%, 0%, 0.161) 1px;
}

#RSession th {
  border-top: none;
}

#RSession th:first-child {
  border-top-left-radius: 36px;
  border-left: none;
  border-right: none;
}
#RSession th:last-child {
  border-top-right-radius: 36px;
}
#RSession tr:last-child td:last-child {
  border-bottom-right-radius: 36px;
}
#RSession td:first-child,
#RSession th:first-child {
  border-left: none;
}
#RSession th,
tr {
  height: 5rem;
}
.scroll {
  overflow-x: auto !important;
}
</style>
