/*=========================================================================================
  File Name: modulePatientActions.js
  Description: Patient Module Actions
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import axios from "@/axios.js";

export default {
    AddPatient({ commit }, item) {
        return new Promise((resolve, reject) => {
            axios
                .post("/api/Patient/AddPatient", item)
                .then(response => {
                    commit(
                        "ADD_ITEM",
                        Object.assign(item, { ID: response.data.Data.ID })
                    );
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },

    SearchPatients({ commit }, item) {
        return new Promise((resolve, reject) => {
            debugger
            axios
                .post("/api/Patient/SearchPatients", item)
                .then(response => {
                    commit("SET_Patient", response.data.Data);
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },

    UpdatePatient(context, item) {
        return new Promise((resolve, reject) => {
            axios
                .post("/api/Patient/UpdatePatient", item)
                .then(response => {
                    // commit("UPDATE_Patient", item);
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    getPatientReservationSurgeryCalender({ commit }, item) {
        debugger;
        return new Promise((resolve, reject) => {
            axios
                .post(
                    "/API/PatientReservationSurgery/SearchPatientReservationSurgerys",
                    item
                )
                .then(response => {
                    commit("SET_MonthPatientReservationSurgeries", response.data.Data);
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    getPatientReservationSurgery({ commit }, item) {
        debugger;
        return new Promise((resolve, reject) => {
            axios
                .post(
                    "/API/PatientReservationSurgery/SearchPatientReservationSurgerys",
                    item
                )
                .then(response => {
                    commit("SET_PatientReservationSurgeries", response.data.Data);
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },

    UpdateListPatientMedicalReportRequest(context, item) {
        return new Promise((resolve, reject) => {
            axios
                .post(
                    "/API/PatientMedicalReportRequest/UpdateListPatientMedicalReportRequest",
                    item
                )
                .then(response => {
                    // commit("SET_PatientReservationSurgeries", response.data.Data);
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    UpdateListPatientReservationSessionDiagnosis(context, item) {
        return new Promise((resolve, reject) => {
            axios
                .post(
                    "/API/Patient/UpdateListPatientReservationSessionDiagnosis",
                    item
                )
                .then(response => {
                    // commit("SET_PatientReservationSurgeries", response.data.Data);
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    UpdatePatientReservationSession(context, item) {
        return new Promise((resolve, reject) => {
            axios
                .post(
                    "/API/PatientReservationSession/UpdatePatientReservationSession",
                    item
                )
                .then(response => {

                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    ChangeReservationJoin(context, reservationId) {
        return new Promise((resolve, reject) => {
            axios
                .get(
                    "/API/PatientReservationSession/ChangeReservationJoin?reservationId="+reservationId,
                )
                .then(response => {

                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    ScheduleAnotherAppintment(context, patientReservationID) {
        return new Promise((resolve, reject) => {
            axios.get("API/PatientReservationSession/ScheduleAnotherAppintment?patientReservationID=" + patientReservationID)
                .then((response) => {
                    resolve(response)
                })
                .catch((error) => { reject(error) })
        })
    },
    CancelSession(context, session) {
        return new Promise((resolve, reject) => {
            axios.post("API/PatientReservationSession/ChangePatientReservationSessionStatus", session)
                .then((response) => {
                    resolve(response)
                })
                .catch((error) => { reject(error) })
        })
    },
    ChangeStatusSession(context, session) {
        return new Promise((resolve, reject) => {
            axios.post("API/PatientReservationSession/ChangePatientReservationSessionStatus", session)
                .then((response) => {
                    resolve(response)
                })
                .catch((error) => { reject(error) })
        })
    },
    getPatientPendingList({ commit }, patientID) {
        return new Promise((resolve, reject) => {
            axios
                .get(
                    "/API/Patient/GetAllPatientPendingAppointmentAndSurgery?patientID=" + patientID
                )
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    AppointmentReservationPaymentDatail(context, item) {
        return new Promise((resolve, reject) => {
            axios
                .post(
                    "/API/PatientReservationSession/AppointmentReservationPaymentDatail",
                    item
                )
                .then(response => {

                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    GetAppointMentTapPayment(context, model) {
        return new Promise((resolve, reject) => {
            debugger;
            axios
                .get(
                    "api/PatientReservationSession/GetAppointMentTapPayment?appointmentID=" +model.appointmentID+"&OrderID="+model.OrderID )
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    GetSurgeryTapPayment(context, model) {
        return new Promise((resolve, reject) => {
            debugger;
            axios
                .get(
                    "api/PatientReservationSurgery/GetSurgeryTapPayment?surgeryReservistionID=" +model.surgeryReservistionID+"&OrderID="+model.OrderID )
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    GetPatientRedervedDoctor(context, item) {
        return new Promise((resolve, reject) => {
            axios
                .post(
                    "/API/PatientReservationSession/GetPatientRedervedDoctor",
                    item
                )
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },

    ProcessingPaymentDatail(context, item) {
        debugger;
        return new Promise((resolve, reject) => {
            axios
                .post(
                    "/API/PatientReservationSurgery/ProcessingPaymentDatail",
                    item
                )
                .then(response => {

                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    SurgeryPaymentDatail(context, item) {
        return new Promise((resolve, reject) => {
            axios
                .post(
                    "/API/PatientReservationSurgery/SurgeryPaymentDatail",
                    item
                )
                .then(response => {

                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    AddPatientReservationSurgery(context, item) {
        return new Promise((resolve, reject) => {
            axios
                .post(
                    "/API/PatientReservationSurgery/AddPatientReservationSurgery",
                    item
                )
                .then(response => {
                    // commit("SET_PatientReservationSurgeries", response.data.Data);
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    AddPatientMedicalDocument(context, item) {
        debugger
        return new Promise((resolve, reject) => {
            axios
                .post(
                    "/API/Patient/AddPatientMedicalDocument",
                    item
                )
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    DeletePatientMedicalDocument(context, item) {
        debugger
        return new Promise((resolve, reject) => {
            axios
                .post(
                    "/API/Patient/DeletePatientMedicalDocument",
                    item
                )
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    AddListPatientMedicalDocument(context, item) {
        debugger
        return new Promise((resolve, reject) => {
            axios
                .post(
                    "/API/Patient/AddListPatientMedicalDocument",
                    item
                )
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    GetPatient({ commit }, itemid) {
        debugger;
        return new Promise((resolve, reject) => {
            axios
                .get("/api/Patient/GetPatientByID?patientID=" + itemid)
                .then(response => {
                    resolve(response);

                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    GetPatientForMainData({ commit }, itemid) {
        debugger;
        return new Promise((resolve, reject) => {
            axios
                .get("/api/Patient/GetPatientForMainData?patientID=" + itemid)
                .then(response => {
                    resolve(response);

                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    GetAllPatientMedicalReports(context, itemid) {
        debugger;
        return new Promise((resolve, reject) => {
            axios
                .post("/api/Patient/GetAllPatientMedicalReports?patientID=" + itemid)
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },


    GetAllProfilePatientMedicalReports(context, itemid) {
        debugger;
        return new Promise((resolve, reject) => {
            axios
                .post("/api/Patient/GetAllProfilePatientMedicalReports?patientID=" + itemid)
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
  
    DeletePatient(context, item) {
        debugger;
        return new Promise((resolve, reject) => {
            axios
                .get("/api/Patient/DeletePatient?id=" + item)
                .then(response => {
                    // commit("REMOVE_ITEM", item.ID);
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    SearchPatientCancelledReservationSessions(context, item) {
        return new Promise((resolve, reject) => {
            axios
                .post(
                    "/API/PatientReservationSession/SearchPatientCancelledReservationSessions",
                    item
                )
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    SearchPatientReservationSessions(context, item) {
        return new Promise((resolve, reject) => {
            axios
                .post(
                    "/API/PatientReservationSession/SearchPatientReservationSessions",
                    item
                )
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    SearchReservationSessionPrescriptions(context, item) {
        return new Promise((resolve, reject) => {
            axios
                .post(
                    "/API/PatientReservationSessionPrescription/SearchPatientReservationSessionPrescriptions",
                    item
                )
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },

    GetPatientReservationSessionPrescription({ commit }, patientReservationID) {
        return new Promise((resolve, reject) => {
            axios
                .get(
                    "/API/PatientReservationSessionPrescription/GetPatientReservationSessionPrescription?patientReservationID=" + patientReservationID
                )
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    GetAllUserNotificationSettings({ commit }) {
        return new Promise((resolve, reject) => {
            axios
                .get("/API/UserNotificationSetting/GetAllUserNotificationSettings")
                .then(response => {
                    commit("SET_Patient", response.data.Data);
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    GetUserNotificationSettings({ commit }) {
        return new Promise((resolve, reject) => {
            axios
                .get("/API/UserNotificationSetting/GetUserNotificationSettings")
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    GetPatientReservationSurgery(context, ID) { 
        debugger;
        return new Promise((resolve, reject) => {
            axios
                .get(
                    "/API/PatientReservationSurgery/GetPatientReservationSurgery?reservationSurgeryID=" +
                    ID
                )
                .then(response => {
                    // commit("SET_Patient", response.data.Data);
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    GetHospitalSurgery(context, ID) {
        debugger
        return new Promise((resolve, reject) => {
            axios
                .get(
                    "/API/HospitalSurgery/GetHospitalSurgery?ID=" +
                    ID
                )
                .then(response => {
                    debugger
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    }, 
    GetSurgeryDocuments({ commit }, ID) {
        return new Promise((resolve, reject) => {
            axios
                .get(
                    "/API/PatientReservationSurgery/GetPatientReservationSurgeryDocuments?reservationSurgeryID=" +
                    ID
                )
                .then(response => {
                    debugger
                    commit("SET_SurgeryDocuments", response.data.Data)
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },

    UpdateUserNotificationSetting(context, Item) {
        return new Promise((resolve, reject) => {
            axios
                .post("/API/UserNotificationSetting/UpdateUserNotificationSetting", Item)
                .then(response => {

                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    AddUserNotificationSetting(context, Item) {
        return new Promise((resolve, reject) => {
            axios
                .post("/API/UserNotificationSetting/AddUserNotificationSetting", Item)
                .then(response => {

                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    SearchPatientMedicalHistoryReports(context, Item) {
        return new Promise((resolve, reject) => {
            axios
                .post(
                    "/API/PatientMedicalHistoryReport/SearchPatientMedicalHistoryReports",
                    Item
                )
                .then(response => {
                    // commit("SET_Patient", response.data.Data);
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    SearchSurgeryAndTreatment({ commit }, Item) {
        return new Promise((resolve, reject) => {
            axios
                .post(
                    "/API/PatientMedicalHistoryReport/SearchPatientMedicalHistoryReports",
                    Item
                )
                .then(response => {
                    commit("SET_SurgeryAndTreatment", response.data.Data);
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    AddPatientMedicalHistoryReport(context, Item) {
        return new Promise((resolve, reject) => {
            axios
                .post(
                    "/API/PatientMedicalHistoryReport/AddPatientMedicalHistoryReport",
                    Item
                )
                .then(response => {
                    // commit("SET_Patient", response.data.Data);
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    AddPatientMedicalReportRequest(context, Item) {
        debugger;
        return new Promise((resolve, reject) => {
            axios
                .post(
                    "/API/PatientMedicalReportRequest/AddPatientMedicalReportRequest",
                    Item
                )
                .then(response => {
                    // commit("SET_Patient", response.data.Data);
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },

    PatientMedicalReportRequest({ commit }, item) {
        debugger;
        return new Promise((resolve, reject) => {
            axios
                .post("/api/PatientMedicalReportRequest/SearchPatientMedicalReportRequests", item)
                .then(response => {
                    debugger
                    response.data.Message
                    commit("SET_PEND_MEDICAL_REPORT", response.data.Data);
                    debugger
                    resolve(response);
                })
                .catch(error => {
                    debugger
                    reject(error);
                });
        });
    },
    GetPatientMedicalHoistory(context, ID) {
        return new Promise((resolve, reject) => {
            axios
                .get(
                    "/API/PatientMedicalHistory/GetPatientMedicalHistory/?patientId=" +
                    ID
                )
                .then(response => {
                    // commit("SET_Patient", response.data.Data);
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },

    SendInvoiceEmail({ commit }, item) {
        debugger
        return new Promise((resolve, reject) => {
            axios.post("/api/Patient/SendInvoiceEmail",
                item)
                .then((response) => {

                    resolve(response)
                })
                .catch((error) => { reject(error) })
        })
    },


    GetGeneralSetting({ commit }) {
        debugger
        return new Promise((resolve, reject) => {
            axios.get("/api/Setting/GetGeneralSetting")
                .then((response) => {

                    resolve(response)
                })
                .catch((error) => { reject(error) })
        })
    },
    

};